body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mq-math-mode,
.mq-math-mode .mq-editable-field {
  position: absolute;
  height: 40px;
  width: 90%;
  background-color: #d6f2ff;
}

.mq-math-mode * {
  font-family: Quicksand, sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  padding-right: 1px;
  color: #333;
}